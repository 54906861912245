<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'CitizenStatements.EuolStatement' })"
                show-expand
                @item-expanded="itemExpanded"
                :expanded.sync="expanded"
                >

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
                    <v-btn 
                        icon
                        x-small
                        plain
                        class="expand-cust-btn"
                        @click="expand(true)" 
                        v-if="!isExpanded"
                    >
                        <v-icon>
                            fas fa-caret-right
                        </v-icon>
                    </v-btn>
                    <v-btn 
                        icon
                        x-small
                        plain
                        class="expand-cust-btn"
                        @click="expand(false)" 
                        v-else
                    >
                        <v-icon>
                            fas fa-caret-down
                        </v-icon>
                    </v-btn>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <div class="table-expanded-wrapper">

                            <template v-if="item._info">
                                <v-treeview 
                                    dense
                                    :items="item._info"
                                    class="cust-treeview-in-list"
                                    :open.sync="open[item.id]"
                                    >
                                    <template v-slot:label="{ item }">

                                        <template v-if="item.text=='Link'">
                                                
                                            <div class="d-flex align-center">
                                                <span class="linked-docs-title">{{ $t(GetLinkedTypes[item.type]) }}</span>
                                                <div class="onlyReadData more-per-lab-wrap">
                                                    <v-doc-chip
                                                        v-for="(doc, index) in item.values"
                                                        :key="item.type+index"
                                                        :id="doc.values[6]"
                                                        :link-id="doc.values[0]"
                                                        :name="$t(GetDocumentTypes[doc.values[9]]) + ' № ' + doc.values[2] + ` ${$t('от_время')} ` + doc.values[3] + (doc.values[5] ? ' (' + doc.values[5] + ')' : '')" 
                                                        iconColor="#7289DA"
                                                        :is-linked-document="true"
                                                        :source-document-id="item.sourceDocumentId"
                                                    />
                                                </div>
                                            </div>

                                        </template>
                                        <template v-else-if="item.text=='Notify'">
                                            <div class="d-flex ctil-row-wrap">
                                                <div class="flex-grow-1">
                                                    <div class="elipsis-text-wrap">
                                                        <span>
                                                            {{ $t("Уведомление") }}
                                                        </span>
                                                        <span>
                                                            {{ item.values[0] }}
                                                        </span>
                                                        <span>
                                                            <v-employee-chip :id="item.values[3]" :name="item.values[2]" /> 
                                                        </span>
                                                        <div class="etw-text-container" v-tooltip.top-center="item.values[1]">
                                                            <span class="etw-text">
                                                                {{ item.values[1] }}
                                                            </span>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>                                           
                                        </template>
                                        <template v-else-if="item.text=='0'">
                                            <div class="d-flex ctil-row-wrap">
                                                <div class="d-flex align-center flex-grow-1">
                                                    <div class="elipsis-text-wrap">
                                                        <span 
                                                            v-if="item.values[14] == 'True'"
                                                            :class="item.values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                        >
                                                            {{ $t("Резолюция") }}
                                                        </span>
                                                        <span 
                                                            v-else
                                                            :class="item.values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                        >
                                                            {{ $t("Внешняя") }}
                                                        </span>
                                                        
                                                        <span :class="item.values[5] == 7 ? 'text-decoration-line-through' : ''">
                                                            {{ item.values[8] }}
                                                        </span>

                                                        <v-employee-chip v-if="item.values[14] == 'True'" :id="item.values[7]" :name="item.values[1]" :is-employee="true" />
                                                        <v-enterprise-chip v-else :name="item.values[1]" />

                                                        <i v-if="item.values[13] === 'True'" class="fas fa-glasses signer-control-i" v-tooltip.top-center="isSignerControlTooltip"></i>

                                                        <div class="etw-text-container" v-tooltip.top-center="item.values[2]">
                                                            <span :class="[item.values[5] == 7 ? 'text-decoration-line-through' : '', 'etw-text']">
                                                                {{ item.values[2] }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                    <span 
                                                        v-if="dateFormat(item.values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')"
                                                        :class="item.values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                    >
                                                        {{ dateFormat(item.values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                    </span>
                                                </div>
                                                <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                    <div class="onlyReadData more-per-lab-wrap"> 
                                                        <StatusChip
                                                            v-if="item.values[14] == 'False' && item.hasExecutions"
                                                            :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.values[5] || '-1', 10))]"
                                                        />

                                                        <template v-else-if="item.values[14] == 'True'">
                                                            <StatusChip
                                                                v-if="dateFormat(item.values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss') && getDaysDiff(item.values[4]) > -1 && item.values[5] == '5'"
                                                                :status="avaibleStatuses[$helpers.getTaskStatus(1)]"
                                                            />
                                                            <StatusChip
                                                                v-else
                                                                :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.values[5] || '-1'), 10)]"
                                                            />

                                                        </template>
                                                        
                                                        <StatusChip v-if="item.values[19] || null" :status="avaibleStatuses[$options.rssStatusKeys[parseInt(item.values[19] || '0')]]" />                    
                                                    </div>
                                                </div>
                                            </div>                                              
                                        </template>
                                        <template v-else-if="item.text=='1'">
                                            <div class="d-flex ctil-row-wrap">
                                                <div class="d-flex align-center flex-grow-1">
                                                    <div class="elipsis-text-wrap">
                                                        <span>{{$t("Исполнитель")}}</span>
                                                        <v-enterprise-chip
                                                            v-if="item.values[9] === '00000000-0000-0000-0000-000000000000'"
                                                            :name="item.values[1]"
                                                            :iconColor="executerStatusColor(item.values[8])"
                                                            :main="item.values[6] == 'True'"
                                                        />
                                                        <v-workplace-chip
                                                            v-else
                                                            :id="item.values[9]"
                                                            :name="item.values[0]"
                                                            :iconColor="executerStatusColor(item.values[8])"
                                                            :main="item.values[6] == 'True'"
                                                            :is-employee="true"
                                                        />

                                                        <div class="etw-text-container" v-tooltip.top-center="item.values[5]">
                                                            <span class="etw-text">
                                                                {{ item.values[5] }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                    <span v-if="dateFormat(item.values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')">
                                                        {{ dateFormat(item.values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                    </span>
                                                </div>
                                                <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                    <StatusChip :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.values[2] || '-1', 10))]" />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else-if="item.text=='2'">
                                            <div class="d-flex ctil-row-wrap">
                                                <div class="d-flex align-center flex-grow-1">
                                                    <div class="elipsis-text-wrap">
                                                        <span>
                                                            {{ $t("Пункт") }} № {{ item.values[15] }}
                                                        </span>
                                                        
                                                        <span>
                                                            {{ item.values[8] }}
                                                        </span>

                                                        <v-employee-chip v-if="item.values[14] == 'True'" :id="item.values[7]" :name="item.values[1]" :is-employee="true" />
                                                        <v-enterprise-chip v-else :name="item.values[1]" />

                                                        <i v-if="item.values[13] === 'True'" class="fas fa-glasses signer-control-i" v-tooltip.top-center="isSignerControlTooltip"></i>

                                                        <div class="etw-text-container" v-tooltip.top-center="item.values[2]">
                                                            <span class="etw-text">
                                                                {{ item.values[2] }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                    <span v-if="dateFormat(item.values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')">
                                                        {{ dateFormat(item.values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                    </span>
                                                </div>
                                                <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                    <div class="onlyReadData more-per-lab-wrap"> 
                                                        <StatusChip
                                                            v-if="item.values[14] == 'False' && item.hasExecutions"
                                                            :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.values[5] || '-1', 10))]"
                                                        />

                                                        <template v-else-if="item.values[14] == 'True'">
                                                            <StatusChip
                                                                v-if="dateFormat(item.values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss') && getDaysDiff(item.values[4]) > -1 && item.values[5] == '5'"
                                                                :status="avaibleStatuses[$helpers.getTaskStatus(1)]"
                                                            />
                                                            <StatusChip
                                                                v-else
                                                                :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.values[5] || '-1'), 10)]"
                                                            />

                                                        </template>
                                                        
                                                        <StatusChip v-if="item.values[19] || null" :status="avaibleStatuses[$options.rssStatusKeys[parseInt(item.values[19] || '0')]]" />                    
                                                    </div>
                                                </div>
                                            </div>        
                                        </template>                                      
                                        <template v-else>
                                            {{ getLabel(item) }}
                                        </template>
                                        
                                    </template>
                                </v-treeview>
                            </template>
                            <v-progress-linear v-else
                                color="#a94442"
                                indeterminate
                                rounded
                                height="6"
                            ></v-progress-linear>

                        </div>
                    </td>
                </template>

                <template v-slot:[`item.regdate`]="{ item }">
                    <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.declarant`]="{ item }">
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.declarant">
                            {{item.declarant}}
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.annotation`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <v-chip
                            v-if="item.ordernumber > 0"
                            class="table-label sendedstatus"                            
                            small
                            label                            
                            :outlined="!item.sendedtoaikyn"
                            :color="item.sendedtoaikyn ? 'var(--d-green)' : '' "   
                            :text-color="item.sendedtoaikyn ? 'white' : ''"
                        >
                            <div>{{$t("Версия")}} {{item.ordernumber}}</div>
                        </v-chip>
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.annotation">
                                {{item.annotation}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.controldate`]="{ item }">
                    <div>{{dateFormat(item.controldate, 'DD.MM.YYYY')}}</div>
                </template>
                
                <template v-slot:[`item.answerstatus`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <StatusChip :status="getEuolExecutionStatus(item)"/>
                        <StatusChip :status="getEuolAnswerStatus(item)"/>
                    </div>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from "axios";
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system"
import adapter from "@/services/adapter";
import StatusChip from '@/components/StatusChip';
import { mapGetters } from 'vuex';

export default {
    rssStatusKeys:["", "rssOnSign", "rssSignedByChief", "rssSended"],
    columnIndex:  {
        'regnumber': 2,
        'regdate': 3,
        'uniquenumber': 4,
        'controldate': 5,
        'declarant': 6,
        'annotation': 14
    },
    euolRegStates: {
        "Registered": 1,
        "Repeat": -200,
        "Duplicate": -201
    },
    components:{
        StatusChip
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Обратившееся_лицо"), value: 'declarant', width: '15%' },
                { text: i18n.t("Краткое_содержание"), value: 'annotation' },
                { text: i18n.t("Уникальный_номер"), value: 'uniquenumber', width: '160px' },
                { text: i18n.t("Срок"), value: 'controldate', width: '110px' },
                { text: i18n.t("Статус"), value: 'answerstatus', width: '180px', sortable: false, class: 'status-col' },
            ],
            total: 0,
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
            expanded: [],
            open: {},
        }
    },
    computed: {
        ...mapGetters('references', ['GetLinkedTypes', 'GetDocumentTypes']),
        options: {
            get: function() {
                return this.$store.getters['correspondence/euolstatemets/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('correspondence/euolstatemets/SET_TABLE_OPTION', newValue);
            }
        },
        collection() {
            return this.$store.getters['correspondence/getCollection'];
        },
        filter() {
            return {
                ...this.options,
                SearchStr: this.search,
                RegState: this.$options.euolRegStates[this.status],
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
        extendedTableFilter() {
            return this.$store.getters['correspondence/getExtendedFilter'];
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
        isSignerControlTooltip () {
            return this.$t("Личный_контроль");
        },
    },
    methods: {
        async itemExpanded(val) {

            if (!val.value)
                return;
            
            this.$set(val.item, '_info', null)

            let response = await httpAPI({
                url: `api/correspondence/getdocumentchildren?id=${val.item.id}`,
                method: 'GET',               
            });
            
            let data = response?.data?.payload?.data?.object?.data;
            
            if (data)
            {
                let treeData = adapter.transformTreeData(null, data, val.item.id);
                this.$set(val.item, '_info', treeData)
            }   
        },
        getLabel(item){
            return item.values.toString();
        },
        resetPage() {
            this.$store.commit('correspondence/euolstatemets/SET_TABLE_OPTION_PAGE', 1);
        },
        getEuolAnswerStatus(source) {            
            var key = this.$helpers.getEuolAnswerStatus(source);
            return this.avaibleStatuses[key];
        },
        getEuolExecutionStatus(source) {
            var key = this.$helpers.getEuolExecutionStatus(source, this.currentTimestamp);
            return this.avaibleStatuses[key];
        },
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },        
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            this.$emit("change_loaddata_status",this.loading);
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/correspondence/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.currentTimestamp = response?.data?.payload?.currentTimestamp ?? Date.now();
            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
            this.$emit("change_loaddata_status",this.loading);
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, RegState, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 3;
            return { 
                SearchStr,
                RegState,
                IsGovService: "0",
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage,
                ...extendedFilterData
            };
        },
        dateFormat: sys.dateFormat,
        getDaysDiff(controlDate) {
            let diff = this.$moment(controlDate,'DD.MM.YYYY HH:mm:ss').diff(this.$moment(Date.now()), 'days');
            return diff;
        },
        executerStatusColor(item) {
            switch(item)
            {
                case 'success':
                    return "#43B581";
                case 'warning':
                    return "#FAA61A";
                case 'danger':
                    return "#F04747";
                default:
                    return null;
            }
        },
        async oneStatusColWidth() {
            var parent = document.getElementsByClassName('content-box')[0];
            var status_col_obj = parent.getElementsByClassName('status-col');
            var status_dropdown_col_obj = parent.getElementsByClassName('status-dropdown-col');
            var status_col_w = status_col_obj.length > 0 ? status_col_obj[0].clientWidth : 0;
            if(status_dropdown_col_obj.length > 0){
                for (var index = 0; index < status_dropdown_col_obj.length; index++) {
                    status_dropdown_col_obj[index].style.width = status_col_w + "px";
                }
            }
        }
    },
    mounted() {
        this.$nextTick(this.oneStatusColWidth);
        window.addEventListener('resize', this.oneStatusColWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.oneStatusColWidth);
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
        open: {
            handler() {
                this.$nextTick(this.oneStatusColWidth);
            },
            deep: true,
        }
    },
}
</script>
