<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.task_id, type: 'Documents|Orders|CitizenStatements.ResolutionTask' })">

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>
                
                <template v-slot:[`item.regnumber`]="{ item }">
                    <v-document-link
                        :id="item.doc_id"
                        :type="'Chancellery|Documents.Document'"
                        :text="item.regnumber"
                    />
                </template>

                <template v-slot:[`item.regdate`]="{ item }">
                    <div>{{ dateFormat(item.regdate, 'DD.MM.YYYY') }}</div>
                </template>

                <template v-slot:[`item.resolutiontext`]="{ item }">
                    <div class="elipsis-text-wrap">                        
                        <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.resolutiontext">
                                {{ item.resolutiontext }}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.currentlimit`]="{ item }">
                    <div>{{ dateFormat(item.currentlimit, 'DD.MM.YYYY') }}</div>
                </template>

                <template v-slot:[`item.executername`]="{ item }">
                    <v-workplace-chip v-if="item.executerid" :id="item.executerid" :name="item.executername" />
                    <v-enterprise-chip v-else :name="item.executername" />
                </template>

                <template v-slot:[`item.workstatus`]="{ item }">
                    <StatusChip :status="availableStatuses[mapAvroraStatus(item.workstatus)]"/>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system";
import StatusChip from '@/components/StatusChip'

export default {
    columnIndex:  {
        'regnumber': 3,
        'regdate': 4,
        'resolutiontext': 5,
        'currentlimit': 6,
        'executername': 7
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    components: {
        StatusChip
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Краткое_содержание"), value: 'resolutiontext'},
                { text: i18n.t("Срок_исполнения"), value: 'currentlimit', width: '150px' },
                { text: i18n.t("Исполнитель"), value: 'executername', width: '15%' },
                { text: i18n.t("Статус"), value: 'workstatus', width: '150px', sortable: false }
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        availableStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
        options: {
            get: function() {
                return this.$store.getters['correspondence/inneractitems/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('correspondence/inneractitems/SET_TABLE_OPTION', newValue);
            }
        },
        collection() {
            return this.$store.getters['correspondence/getCollection'];
        },
        extendedTableFilter() {
            return this.$store.getters['correspondence/getExtendedFilter'];
        },
        filter() {
            return {
                ...this.options,
                SearchStr: this.search,
                ResolutionType: this.status,
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        }
    },
    methods: {
        resetPage() {
            this.$store.commit('correspondence/inneractitems/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            this.$emit("change_loaddata_status",this.loading);
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/correspondence/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
            this.$emit("change_loaddata_status",this.loading);
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, ResolutionType, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "currentlimit" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 6;
            return { 
                SearchStr,
                ResolutionType,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage,
                ...extendedFilterData
            };
        },
        dateFormat: sys.dateFormat,
        mapAvroraStatus(avroraStatus) {
            switch (avroraStatus)
            {
                case 'Закрыто':
                    return 'executed';
                case 'Просрочено':
                    return 'expired';
                case 'Отправлено_на_доработку':
                    return 'needRework';
                case 'Дан_ответ':
                    return 'onExecutionCheck';
                case 'Новое':
                case 'В_работе':
                    return 'onExecution';
                default:
                    return 'unknown';
            }
        }
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
